import React from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Dropdown, Avatar, theme } from "antd";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolIcon from "@mui/icons-material/School";
import CollectionsIcon from "@mui/icons-material/Collections";
import { Link, useNavigate, Routes, Route } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import EventsDashbaord from "./dashbaord/EventsList";
import Dashboard from "./dashbaord/Dashbaord";

const { Header, Content, Footer, Sider } = Layout;

const currentYear = new Date().getFullYear();

const DashboardPage = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const { logOut, user } = UserAuth();
  const username = user?.displayName || "User";

  const handleSignOut = async () => {
    try {
      await logOut();
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <UserOutlined />
        <span>Profile</span>
      </Menu.Item>
      <Menu.Item key="1" onClick={handleSignOut}>
        <LogoutOutlined />
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="min-h-screen">
      <Header
        style={{
          textAlign: "center",
          backgroundColor: "#f5f5f5",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div></div>
        <Dropdown overlay={menu} trigger={["click"]}>
          <div onClick={(e) => e.preventDefault()}>
            <Avatar icon={<UserOutlined />} />
            <span style={{ marginLeft: 8 }}>{username}</span>
          </div>
        </Dropdown>
      </Header>
      <Layout
        style={{ background: colorBgContainer, borderRadius: borderRadiusLG }}
        className="min-h-screen"
      >
        <Sider style={{ background: "#f5f5f5", height: "100%" }} width={200}>
          <Box
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "#f5f5f5",
              color: "#006198",
              height: "100%",
              height: "1500px",
            }}
          >
            <nav aria-label="main mailbox folders">
              <List>
                {/* <ListItem disablePadding>
                  <ListItemButton component={Link} to="/dashboard">
                    <ListItemIcon>
                      <DashboardIcon style={{ color: "#006198" }} />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                </ListItem>
                <Divider /> */}
                {/* <ListItem disablePadding>
                  <ListItemButton component={Link} to="/dashboard/academic">
                    <ListItemIcon>
                      <SchoolIcon style={{ color: "#006198" }} />
                    </ListItemIcon>
                    <ListItemText primary="Academic" />
                  </ListItemButton>
                </ListItem>
                <Divider /> */}
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/dashboard/events">
                    <ListItemIcon>
                      <CollectionsIcon style={{ color: "#006198" }} />
                    </ListItemIcon>
                    <ListItemText primary="Our Events" />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </List>
            </nav>
          </Box>
        </Sider>
        <Content>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="academic" element={<div>academic</div>} />
            <Route path="events" element={<EventsDashbaord />} />
          </Routes>
        </Content>
      </Layout>
      <Footer style={{ textAlign: "center" }}>
        <p className="text-center text-[#006198] mt-1">
          <span className="italic">© Done at TTC Mururu {currentYear} </span> |
          Privacy policy
        </p>
      </Footer>
    </Layout>
  );
};

export default DashboardPage;
