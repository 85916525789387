import React, { useState, useEffect } from "react";
import { Form, Input, Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { firestore, storage } from "../firebase/confing";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";

const EventsForm = ({ setVisible, event }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (event) {
      form.setFieldsValue({
        title: event.title,
        description: event.description,
        image: event.image ? [{ url: event.image }] : [],
      });
    }
  }, [event, form]);

  const handleImageUpload = async (file) => {
    try {
      const storageRef = ref(storage, `images/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      message.error("Failed to upload image");
      return null;
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const { title, description, image } = values;
    const file = image && image[0] && image[0].originFileObj;

    let uploadedImageUrl = event?.image || null;
    if (file) {
      uploadedImageUrl = await handleImageUpload(file);
    }

    if (!uploadedImageUrl) {
      message.error("Failed to add/update event: Image file is missing");
      setLoading(false);
      return;
    }

    try {
      if (event) {
        await updateDoc(doc(firestore, "events", event.id), {
          title,
          description: description || "",
          image: uploadedImageUrl,
        });
        message.success("Event updated successfully!");
      } else {
        await addDoc(collection(firestore, "events"), {
          title,
          description: description || "",
          image: uploadedImageUrl,
        });
        message.success("Event added successfully!");
      }
      form.resetFields();
      setVisible(true); // Indicate that the events list should be updated
    } catch (error) {
      console.error("Error adding/updating event:", error);
      message.error("Failed to add/update event: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item
        name="title"
        label="Event Title"
        rules={[{ required: true, message: "Please enter the event title" }]}
      >
        <Input placeholder="Enter event title" />
      </Form.Item>

      <Form.Item
        name="description"
        label="Event Description"
        rules={[{ required: false, message: "Please enter the event description" }]}
      >
        <Input.TextArea placeholder="Enter event description" />
      </Form.Item>

      <Form.Item
        name="image"
        label="Event Image"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: "Please upload an image" }]}
      >
        <Upload
          listType="picture"
          maxCount={1}
          beforeUpload={() => false}
        >
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>
      </Form.Item>

      <Form.Item>
        <Button
          className="bg-blue-700 text-white"
          htmlType="submit"
          loading={loading}
        >
          {event ? "Update Event" : "Add Event"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EventsForm;
