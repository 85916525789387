import React, { useState, useEffect } from "react";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Image, message } from "antd";
import PropTypes from "prop-types";
import { firestore } from "../firebase/confing"; // Adjust the import path as needed
import { collection, getDocs } from "firebase/firestore";

const Events = ({ id }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(getSlidesToShow());
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "events"));
        const events = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCards(events);
      } catch (error) {
        console.error("Error fetching events:", error);
        message.error("Failed to fetch events: " + error.message);
      }
    };

    fetchEvents();
  }, []);

  function getSlidesToShow() {
    return window.innerWidth < 1024 ? 1 : 3;
  }

  useEffect(() => {
    function handleResize() {
      setSlidesToShow(getSlidesToShow());
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const totalSlides = Math.ceil(cards.length / slidesToShow);

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  const renderSlides = () => {
    const startIndex = currentSlide * slidesToShow;
    const endIndex = startIndex + slidesToShow;
    return cards.slice(startIndex, endIndex).map((card) => (
      <div key={card.id} className="slide px-2">
        <Image
          src={card.image}
          alt="Event Image"
          className="object-cover cursor-pointer"
          style={{ height: "400px", width: "100%" }}
        />
        <div className="text-[#006198] font-poppins cursor-pointer">
          {card.title}
        </div>
      </div>
    ));
  };

  return (
    <div className="capitalize h-screen" id={id}>
      <div className="mx-auto py-20 w-[80%]">
        <h1 className="text-xl font-bold w-full text-[#006198] pt-10 text-center">
          Event & Gallery
        </h1>
        <Image.PreviewGroup>
          <div className="flex flex-row mt-7 pt-12 justify-between px-10">
            <LeftCircleOutlined
              onClick={goToPreviousSlide}
              className="outline-none focus:outline-none pt-30 text-[20px]"
            />
            {renderSlides()}
            <RightCircleOutlined
              onClick={goToNextSlide}
              className="outline-none focus:outline-none pt-30 text-[20px]"
            />
          </div>
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

Events.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Events;
