import React from "react";
import Events from "../component/Events";
import Footer from "../component/Footer";
import Academic from "../component/Academic";
import About from "../component/About";
import Home from "../component/Home";
import Navbar from "../component/Navbar";

const LoginPage = () => {
  return (
    <div className="min-h-screen flex flex-col ">
      <Navbar />
      <Home id="home" />
      {/* <About id="about" /> */}
      <Events id="events" />
      <Academic id="academic" />
      <Footer />
    </div>
  );
};

export default LoginPage;
