import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDc3RGfMJSyGgYy_3Yw6kmPC98mpxwB3K8",
  authDomain: "ttcmururuwebsite.firebaseapp.com",
  projectId: "ttcmururuwebsite",
  storageBucket: "ttcmururuwebsite.appspot.com",
  messagingSenderId: "615822536775",
  appId: "1:615822536775:web:7000b768d01c472300c162"
};

const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const database = getDatabase();

export { firestore, storage, auth,database };
