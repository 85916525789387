import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Student from "../assets/angelus.jpg";
import Assemble from "../assets/assemble.jpg";

const Home = ({ id }) => {
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const fadeTimer = setTimeout(() => {
      setFadeIn(!fadeIn);
    }, 3000);

    return () => clearTimeout(fadeTimer);
  }, [fadeIn]);

  return (
    <div id={id} className="relative min-h-screen pl-5">
      <div
        style={{
          backgroundImage: `url(${Assemble})`,
          opacity: fadeIn ? 1 : 0,
          transition: "opacity 1s ease-in-out",
        }}
        className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat bg-center z-[-2]"
      ></div>
      <div
        style={{
          backgroundImage: `url(${Student})`,
          opacity: fadeIn ? 0 : 1,
          transition: "opacity 1s ease-in-out",
        }}
        className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat bg-center z-[-1]"
      ></div>
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-0"></div>
      <div className="h-full flex items-center justify-center capitalize">
        <div className="max-w-xl mx-auto px-3 text-center">
          <p className="text-[#35adf3] text-4xl font-bold font-serif pt-[500px]">
            Welcome to TTC Mururu
          </p>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Home;
