import React, { useState, useEffect } from "react";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Card, Table } from "antd";
import PropTypes from "prop-types";

const columns = [
  {
    title: "Classes",
    dataIndex: "classes",
    key: "classes",
  },
  {
    title: "Boys",
    dataIndex: "boys",
    key: "boys",
  },
  {
    title: "Girls",
    dataIndex: "girls",
    key: "girls",
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
  },
];

const data = [
  {
    key: "1",
    classes: "Year 1 SME A&B, LE, SSE, ECLPE",
    boys: 123,
    girls: 137,
    total: 260,
  },
  {
    key: "2",
    classes: "Year 2 SME, LE, SSE, ECLPE",
    boys: 126,
    girls: 98,
    total: 224,
  },
  {
    key: "3",
    classes: "Year 3 SME, LE, SSE, ECLPE",
    boys: 121,
    girls: 140,
    total: 261,
  },
  {
    key: "4",
    classes: "Total",
    boys: 403,
    girls: 342,
    total: 745,
  },
];

const columns2 = [
  {
    title: "Academic year",
    dataIndex: "academicYear",
    key: "academicYear",
  },
  {
    title: "Candidates",
    dataIndex: "candidates",
    key: "candidates",
  },
  {
    title: "Completion certificate obtained",
    dataIndex: "completionCertificate",
    key: "completionCertificate",
  },
  {
    title: "Percentage",
    dataIndex: "percentage",
    key: "percentage",
  },
];

const data2 = [
  {
    academicYear: "2000/2001",
    candidates: "54 A’",
    completionCertificate: "50 A’",
    percentage: "92.5%",
  },
  {
    academicYear: "2001/2002",
    candidates: "88 A’",
    completionCertificate: "84 A’",
    percentage: "95.4%",
  },
  {
    academicYear: "2002/2003",
    candidates: "148 A’",
    completionCertificate: "148 A’",
    percentage: "100%",
  },
  {
    academicYear: "2003/2004",
    candidates: "152 A’",
    completionCertificate: "151 A’",
    percentage: "99.3%",
  },
  {
    academicYear: "2005",
    candidates: "118 A’",
    completionCertificate: "108 A’",
    percentage: "91.5%",
  },
  {
    academicYear: "2006",
    candidates: "70 A’",
    completionCertificate: "62 A’",
    percentage: "88.5%",
  },
  {
    academicYear: "2007",
    candidates: "⦁",
    completionCertificate: "75 O’",
    percentage: "",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "81 A’",
    percentage: "⦁",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "78 A’",
    percentage: "⦁",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "96.%", // This seems like a typo, please verify.
    percentage: "",
  },
  {
    academicYear: "2008",
    candidates: "⦁",
    completionCertificate: "40 O’",
    percentage: "",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "77 A’",
    percentage: "⦁",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "77 A’",
    percentage: "⦁",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "100%",
    percentage: "",
  },
  {
    academicYear: "2009",
    candidates: "⦁",
    completionCertificate: "70 O’",
    percentage: "",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "78 A’",
    percentage: "⦁",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "78 A’",
    percentage: "⦁",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "100%",
    percentage: "",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "100%",
    percentage: "",
  },
  {
    academicYear: "2010",
    candidates: "⦁",
    completionCertificate: "92 O’",
    percentage: "⦁",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "34 A’",
    percentage: "⦁",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "34 A’",
    percentage: "⦁",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "100%",
    percentage: "⦁",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "100%",
    percentage: "⦁",
  },
  {
    academicYear: "2011",
    candidates: "⦁",
    completionCertificate: "93 A’",
    percentage: "⦁",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "93 A’",
    percentage: "⦁",
  },
  {
    academicYear: "",
    candidates: "⦁",
    completionCertificate: "100%",
    percentage: "⦁",
  },
];

const Academic = ({ id }) => {
  return (
    <div
      className="bg-white text-black px-4 capitalize min-h-screen overflow-auto"
      id={id}
    >
      <div className="mx-auto py-3 w-[80%]">
        <h1 className="text-xl font-bold w-full text-[#006198] pt-10 text-center">
          {/* ACADEMIC YEAR: 2023-2024 */}
        </h1>

        <div className="flex  gap-5 mt-7 flex-col sm:flex-row">
          <div className="w-[100%] rounded-lg overflow-hidden  h-[300px] overflow-y-auto">
            <h1 className="text-xl font-bold w-full text-[#006198] pt-10 text-center pb-4">
              ACADEMIC YEAR: 2023-2024
            </h1>
            <h3 className="font-poppins text-[#006198] text-center">
              UP TO DATE INFORMATION
            </h3>
            <div className="py-3 px-4 text-[#696969] text-center">
              <p className="text-poppins">
                Brother TWAGIRAYEZU Elie is a principal of TTC Mururu, and
                Athanase NYABYENDA is the Deputy Dean of Studies
              </p>
            </div>
            <h3 className="font-poppins text-[#006198] text-center">
              ADMINISTRATIVE STAFF MEMBERS
            </h3>
            <div className="py-3 px-4 text-[#696969] text-center">
              <p className="text-poppins">
                TTC Mururu has seven administrative staff member which are: the
                Principal, one Deputy Dean of Studies, one Bursar, one Deputy
                Dean of Discipline, one School secretary, one Matron one Patron.
                In the case of decision making on the management of the school;
                the administrative staff, teaching staff, Parents committee
                through general assembly seat together and have common
                agreements.
              </p>
            </div>
          </div>
        </div>
        <div className="flex  gap-5 mt-7 flex-col sm:flex-row">
          <div className="w-[100%] rounded-lg overflow-hidden  h-auto overflow-y-auto">
            <h3 className="font-poppins text-[#006198] text-center">
              NUMBER OF STUDENTS TEACHERS
            </h3>
            <div className="py-3 px-4 text-center text-[#696969]">
              <p className="text-poppins">
                <h3>
                  At the beginning of this academic year 2022-2023, the total
                  number of students teachers is 745; boys are 342 and girls are
                  403. The table below shows those students per their classes.
                  Classes Boys Girls Total
                </h3>
                <Table
                  className="text-[#696969]"
                  columns={columns}
                  dataSource={data}
                  bordered
                  pagination={false}
                  summary={(data) => (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        {data.reduce((prev, curr) => prev + curr.boys, 0)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        {data.reduce((prev, curr) => prev + curr.girls, 0)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        {data.reduce((prev, curr) => prev + curr.total, 0)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                />
              </p>
            </div>
          </div>
        </div>
        <div className="flex  gap-5 mt-7 flex-col sm:flex-row">
          <div className="w-[100%] rounded-lg overflow-hidden h-auto overflow-y-auto">
            <h3 className="font-poppins text-[#006198] text-center">
              TEACHING STAFF MEMBERS
            </h3>
            <div className="py-3 px-4 text-[#696969] text-center">
              <p className="text-poppins">
                There are 29 tutors together with administrative staff: 18 males
                and 11 females.{" "}
              </p>
            </div>
            <h3 className="font-poppins text-[#006198] text-center">
              OTHER EMPLOYEES
            </h3>
            <div className="py-3 px-4 text-[#696969] text-center">
              <p className="text-poppins">
                The school has 5 cooks, 5 securities, one storekeeper, one
                matron, one librarian, one driver, three cleaners and two cow
                boys.{" "}
              </p>
            </div>
            <h3 className="font-poppins text-[#006198] text-center">
              COAT OF ARM, MISSION, MOTTO
            </h3>
            <div className="py-3 px-8 text-[#696969] text-center">
              <p className="text-poppins">
                1. The coat of arm of TTC Mururu is constituted: the letter “M”:
                Marists in the entire world 2. School Motto (Values): Prayer,
                Work and Success 3. School mission: Initial and continuous
                formation of qualified nursery and primary teachers.
              </p>
            </div>
          </div>
        </div>
        <div className="flex  gap-5 mt-7 flex-col sm:flex-row items-center">
          <div className="w-[100%] rounded-lg overflow-hidden  h-auto overflow-y-auto">
            <h3 className="font-poppins text-[#006198] text-center">
              PASSING RATE IN NATIONAL EXAMS
            </h3>
            <div className="py-3 px-8 text-[#696969] text-center">
              After its reopening, from 2001/2002 up to now the passing rate is
              as followed:{" "}
            </div>
            <div className="py-3 text-[#696969] items-center "></div>
            <Table
              columns={columns2}
              dataSource={data2}
              bordered
              className="text-center"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Academic.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Academic;
