import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Typography } from "antd";
import { UserAuth } from "../context/AuthContext";
import { GoogleButton } from "react-google-button";

const { Title } = Typography;

const LandingPage = () => {
  const { googleSignIn, user } = UserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row justify="center" align="middle" className="h-screen bg-gray-100">
      <Col xs={22} sm={16} md={12} lg={8}>
        <div className="p-6 bg-white shadow-md rounded-md">
          <Title level={2} className="text-center">
            Login
          </Title>
          <div className="max-w-[240px] m-auto py-4">
            <GoogleButton onClick={handleGoogleSignIn} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default LandingPage;
