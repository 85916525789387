import React from "react";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import LoginPage from "../pages/LoginPage";
import DashboardPage from "../pages/LayoutPage";
import EventsPage from "../pages/dashbaord/EventsList"; // Import the new EventsPage
import Protected from "../component/Protected";
import About from "../component/About";

const Index = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route  path="/about-us" element={<About />}/>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/dashboard/*"
          element={
            <Protected>
              <DashboardPage />
            </Protected>
          }
        />

      </Routes>
    </Router>
  );
};

export default Index;
