import React, { useState, useEffect } from "react";
import { Button, Modal, message } from "antd";
import EventsForm from "../../component/EventsForm";
import { firestore } from "../../firebase/confing";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";

const EventsList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(null);

  const fetchEvents = async () => {
    try {
      const eventsCollection = collection(firestore, "events");
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsList = eventsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(eventsList);
    } catch (error) {
      console.error("Error fetching events: ", error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleDelete = async (eventId) => {
    try {
      await deleteDoc(doc(firestore, "events", eventId));
      message.success("Event deleted successfully!");
      fetchEvents(); 
    } catch (error) {
 
      message.error("Failed to delete event: " + error.message);
    }
  };

  const showModal = (event = null) => {
    setCurrentEvent(event);
    setModalOpen(true);
  };

  const handleModalClose = (updated) => {
    setModalOpen(false);
    if (updated) {
      fetchEvents();
    }
  };

  return (
    <div className="container mx-auto p-4">
      <Button
        type="primary"
        onClick={() => showModal()}
        className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Add Event
      </Button>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {events.map((event) => (
          <div key={event.id} className="border border-gray-300 rounded-md overflow-hidden shadow-md">
            <img src={event.image} alt={event.title} className="w-full" />
            <div className="p-4">
              <p className="text-lg font-bold mb-2">{event.title}</p>
              <p className="text-gray-700">{event.description}</p>
              <Button
                type="primary"
                danger
                onClick={() => handleDelete(event.id)}
                className="mt-2"
              >
                Delete
              </Button>
              <Button
                type="default"
                onClick={() => showModal(event)}
                className="mt-2 ml-2"
              >
                Edit
              </Button>
            </div>
          </div>
        ))}
      </div>

      
      <Modal
        title={currentEvent ? "Edit Event" : "Add Event"}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={null}
      >
        <EventsForm setVisible={handleModalClose} event={currentEvent} />
      </Modal>
    </div>
  );
};

export default EventsList;
