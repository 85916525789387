import React from "react";
import { Card, Row, Col, Statistic, Table } from "antd";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { UserOutlined, BookOutlined, TeamOutlined } from "@ant-design/icons";


const successRateData = [
  { name: "2015", percentage: 30 },
  { name: "2016", percentage: 90 },
  { name: "2017", percentage: 60 },
  { name: "2018", percentage: 98 },
  { name: "2019", percentage: 50 },
  { name: "2020", percentage: 92.5 },
  { name: "2021", percentage: 92.5 },
  { name: "2022", percentage: 40 },
  { name: "2023", percentage: 95.4 },
  { name: "2024", percentage: 98.0 },
];

const studentData = [
  { year: "Year 1", boys: 123, girls: 137, total: 260 },
  { year: "Year 2", boys: 126, girls: 98, total: 224 },
  { year: "Year 3", boys: 121, girls: 140, total: 261 },
];

const staffData = [
  { name: "Males", value: 18 },
  { name: "Females", value: 11 },
];

const otherEmployeesData = [
  { name: "Cooks", value: 5 },
  { name: "Securities", value: 5 },
  { name: "Storekeeper", value: 1 },
  { name: "Matron", value: 1 },
  { name: "Librarian", value: 1 },
  { name: "Driver", value: 1 },
  { name: "Cleaners", value: 3 },
  { name: "Cow Boys", value: 2 },
];

const columns = [
  { title: "Class", dataIndex: "year", key: "year" },
  { title: "Boys", dataIndex: "boys", key: "boys" },
  { title: "Girls", dataIndex: "girls", key: "girls" },
  { title: "Total", dataIndex: "total", key: "total" },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const Dashboard = () => {
  return (
    <div className="p-4 min-h-screen">
      <Row gutter={16} className="mb-4">
        <Col span={8}>
          <Card className="shadow-lg rounded-lg border-0">
            <Statistic
              title="Total Students"
              value={745}
              prefix={<UserOutlined />}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card className="shadow-lg rounded-lg border-0">
            <Statistic
              title="Total Staff"
              value={29}
              prefix={<TeamOutlined />}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card className="shadow-lg rounded-lg border-0">
            <Statistic
              title="Other Employees"
              value={19}
              prefix={<BookOutlined />}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>

      </Row>

      <Row gutter={16} className="mt-4 flex">
        <Col span={12} className="flex-1">
          <Card className="shadow-lg rounded-lg border-0 h-full">
            <h2 className="text-lg font-bold mb-4">Student Success Rate Comparison</h2>
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart data={successRateData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={(value) => `${value}%`} />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="percentage"
                  stroke="#8884d8"
                  fill="#8884d8"
                />
              </AreaChart>
            </ResponsiveContainer>
          </Card>
        </Col>
        <Col span={12} className="flex-1">
          <Card className="shadow-lg rounded-lg border-0 h-full">
            <h2 className="text-lg font-bold mb-4">Student Enrollment by Year</h2>
            <div className="h-96 overflow-auto">
              <Table dataSource={studentData} columns={columns} pagination={false} />
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} className="mt-4 flex">
        <Col span={12} className="flex-1">
          <Card className="shadow-lg rounded-lg border-0 h-full">
            <h2 className="text-lg font-bold mb-4">Staff Gender Distribution</h2>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={staffData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#82ca9d"
                  label
                >
                  {staffData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Card>
        </Col>

        <Col span={12} className="flex-1">
          <Card className="shadow-lg rounded-lg border-0 h-full">
            <h2 className="text-lg font-bold mb-4">Other Employees Distribution</h2>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={otherEmployeesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
