import './App.css';
import Index from './routes/index';
import { AuthContextProvider } from './context/AuthContext';

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
      <Index/>
      </AuthContextProvider>
    </div>
  );
}

export default App;
