import React from "react";
import PropTypes from "prop-types";
import { Card, List } from "antd";
import Navbar from "./Navbar";
import Footer from "./Footer";

const About = ({ id }) => {
  const listData = [
    "Early Childhood and Lower Primary Education",
    "Social Studies Education",
    "Languages Education",
    "Sciences and Mathematics Education",
  ];

  return (
    <div id={id} className="relative min-h-screen pl-5">
      <Navbar />
      <div className="max-w-6xl mx-auto py-20">
        <h1 className="text-xl font-bold w-full text-[#006198] pt-10 text-center">
          About Us
        </h1>
        <div className="mt-7 w-[100%] overflow-y-auto">
          <div className="grid grid-cols-1 gap-5">
            <Card className="w-[100%] rounded-md overflow-hidden  h-auto">
              <h3 className="font-poppins text-[#006198]">SCHOOL LOCATION</h3>
              <div className="py-3 px-4 text-[#696969]">
                <p className="text-poppins">
                  TTC MURURU is located in Western province, Rusizi District,
                  Mururu Sector, Gahinga Cell. It is about 7 km from Rusizi
                  (Kamembe) city towards the south of Rusizi district, a little
                  bit 3 km of RusiziRiver that borders Rwanda and DRC. From
                  Kamembe to the TTC Mururu, simply take the road
                  Kamembe-Bugarama and turn right towards MAGERWA Mururu. TTC
                  Mururu is teachers’ training school (College) due to the fact
                  that it trains students’ teacher who will be nursery and
                  primary teachers.
                </p>
              </div>
            </Card>
            <Card className="w-[100%] rounded-md overflow-hidden  h-auto">
              <h3 className="font-poppins text-[#006198]">
                HISTORICAL BACKGROUND
              </h3>
              <div className="py-3 px-4 text-[#696969]">
                <p className="text-poppins">
                  In 1968, the school was located at Mibilizi, where it opened
                  firstly its doors under the name of ENP Mibilizi (Ecole
                  Normale Primaire Mibilizi) on the initiative of Catholic
                  Church (Cyangugu Diocese) under subsidiary with Catholic
                  Church and the state. In 1976 the school transferred at Mururu
                  under the control of Marist Brothers. Since 1968, the school
                  received option of lettres and Normale primaire and changed
                  the name of Ecole Normale Primaire to GROUPE SCOLAIRE DE
                  MURURU. The first promotion of the school to pass out was in
                  1992/1993 of Academic Year. With the 1994 Genocide against
                  Tutsi events, the school was seriously damaged and reopened in
                  1997. In 1997/1998 academic year was characterized by
                  rehabilitation of the school and restarting with only one
                  class of secondary (Ordinary Level), under the direction of
                  catholic lays. In 1998/1999 with the idea of putting together
                  all “Ecole Normale Primaire” of the province in order to make
                  one school of normale Primaire, Mururu has chosen and it has
                  taken the name of ENP/TTC MURURU (Ecole Normale Primaire/
                  Teachers Training College Mururu) and received the students of
                  Normale Primaire option. The Mission of the school was to
                  provide basic and continuous education (formation) of
                  Teachers. The GTZ project was there to provide enough teaching
                  and learning materials/resources (in STE) to ENP/TTC and to
                  make sure that the mission of the school is realized. In
                  2003/2004 the school received ordinary level in addition to
                  the advanced level and from the year 2005, the school was
                  directed by Marists Brothers. At the side of partnership, the
                  school has HEUS GYMNASIUM Theodor, the school Rhénanie
                  Palatinat in Germany. Nowadays, the school has four
                  combinations without ordinary level. Those ones are:
                </p>
                <List
                  dataSource={listData}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta description={item} />
                    </List.Item>
                  )}
                />
                <div>ISENGESHO RYO GUSABIRA ISHURI</div>
                <p className="text-poppins">
                  Mana ihoraho igategeka iteka/wowe washatse ko TTC Mururu iba
                  igicumbi cy’uburezi/tugushimiye ko watugize umuryango umwe
                  muri uru rugo dukesha byinshi biguturukaho./ Turakwinginze
                  udutere inkunga mu mirimo wadushinze,/ uduhere umugisha uru
                  rugo/, uwusendereze ku barutuye n’abarugenda bose./ Duhe
                  kugukunda no kugukundisha abandi,/ gukunda umurimo no
                  kuwunoza,/ gukorera ku gihe kandi twitanga/, ubwitonzi no
                  gushishoza,/ kukwiringira mu migirire yacu/, ubwitonzi
                  n’imbaraga zo guhashya icyaha,/ kuvuga ibiboneye no kwiziga/,
                  uduhe amahoro n’ubwumvikane,/ kandi ususurutse imitima yacu
                  iyoboke inzira zawe. Turinde ingeso mbi n’icyaduhungabanya
                  icyo aricyo cyose/. Kandi abacu batashye ubahe iruhuko
                  ridashira/ aho bizeye kuzabana nawe ubuziraherezo. Hamwe na
                  Bikiramariya umubyeyi wacu,/ na Mutagatifu Marcellin
                  Champagnat umurinzi w’urugo,/ turagusaba inema yawe ijye
                  ibanziriza ibyo dukora byose,/ maze dushishikarire amatwara
                  y’ingoma yawe./ Tubigusabye ku bwa Yezu Kristu Umwami wacu.
                  Amina.
                </p>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

About.propTypes = {
  id: PropTypes.string.isRequired,
};

export default About;
