import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { IoArrowUpCircleOutline } from "react-icons/io5";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="bg-[#EEEEEE] py-8 w-full">
      <div className="flex flex-col md:flex-row justify-between max-w-6xl mx-auto">
        <div>
          <h2 className="text-lg font-semibold text-blue-900">Contact Us</h2>
          <div className="flex flex-col gap-2 mt-3 text-[#006198]">
            {["mururuttc@gmail.com"].map((link, index) => (
              <a
                key={index}
                href={`https://mail.google.com/mail/?view=cm&fs=1&to=${link}`}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline cursor-pointer"
              >
                {link}
              </a>
            ))}
          </div>
        </div>
        <div>
          <h2 className="text-lg font-semibold text-blue-900">Be social</h2>
          <div className="flex flex-col gap-4 mt-4 text-[#006198]">
            {[
              {
                icon: FaFacebook,
                label: "Facebook",
                link: "https://www.facebook.com/profile.php?id=61557743124231",
              },
              {
                icon: FaInstagram,
                label: "Instagram",
                link: "https://www.instagram.com/ttcmururu/",
              },
              {
                icon: FaLinkedin,
                label: "LinkedIn",
                link: "https://www.linkedin.com/in/ttc-mururu-928276300/",
              },
              {
                icon: FaYoutube,
                label: "Youtube channel",
                link: "https://www.youtube.com/@ttcmururu_official",
              },
            ].map((social, index) => (
              <a
                href={social.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex gap-2 items-center"
                key={index}
              >
                <social.icon className="text-[#006198] w-[22px]" />
                <span className="hover:underline cursor-pointer capitalize">
                  {social.label}
                </span>
              </a>
            ))}
          </div>
        </div>
        <div>
          <h2 className="text-lg font-semibold text-blue-900">Quick Links</h2>
          <div className="flex flex-col gap-2 mt-3 text-[#006198]">
            <a href="#" className="hover:underline cursor-pointer">
              Home
            </a>
            <a href="#" className="hover:underline cursor-pointer">
              About Us
            </a>
            <a href="#" className="hover:underline cursor-pointer">
              Academic Year
            </a>
            <a href="#" className="hover:underline cursor-pointer">
              Events
            </a>
          </div>
        </div>
        <div className="hidden md:block fixed bottom-4 right-4">
          <button
            onClick={scrollToTop}
            className="flex gap-2 p-3 text-[#006198] bg-[#9BD030] rounded-full items-center cursor-pointer"
          >
            <IoArrowUpCircleOutline className="text-2xl" />
          </button>
        </div>
      </div>
      <div className="h-px bg-gray-300/90 w-11/12 mx-auto mt-10"></div>
      <p className="text-center text-[#006198] mt-1">
        <span className="italic">© Done at TTC Mururu {currentYear} </span> |
        Privacy policy
      </p>
    </footer>
  );
};

export default Footer;
